<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { apiRequest } from "@/helpers/api-call";
//import { format } from "date-fns";

export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      title: "Nouveau message",

      items: [
        {
          text: "Message",
        },
        {
          text: "Nouveau",
          active: true,
        },
      ],

      userTypeList: [
        {
          id: "CONDUCTEUR",
          value: "MOTO",
        },
        {
          id: "PARTICULIER",
          value: "VOITURE",
        },
        {
          id: "TAXIMAN",
          value: "TAXI",
        },
        {
          id: "SOCIETE",

          value: "BUS",
        },
        { id: "PASSAGER", value: "PASSAGER" },
      ],
      valueType: null,
      valueTypeM: null,
      valueExp: null,
      valueBadge: null,
      value: null,
      value1: null,
      type: [
        /*{
          name: "PARTICULIER",
        },
        {
          name: "CONDUCTEUR",
        },*/
        {
          name: "PASSAGER",
        },
        {
          name: " MOTO",
        },
        {
          name: "VOITURE",
        },
        {
          name: "TAXI",
        },
        {
          name: "BUS",
        },
      ],
      experience: [
        {
          name: "R-Débutant",
        },
        {
          name: "R-Qualifié",
        },
        {
          name: "R-Ambassadeur",
        },
      ],
      badge: [
        {
          name: true,
        },
        {
          name: false,
        },
      ],
      tyMessage: [
        {
          name: "Notification",
          value: 0,
        },
        {
          name: "Mail",
          value: 1,
        },
        {
          name: "Notification + Mail",
          value: 2,
        },

        {
          name: "Whatsapp",
          value: 3,
        },
      ],
      tyMedia: [
        {
          name: "Texte",
          value: "text",
        },
        {
          name: "Image",
          value: "image",
        },
        {
          name: "Vidéo",
          value: "video",
        },
      ],
      tyButton: [
        {
          name: "",
          value: "",
        },
        {
          name: "Rublier",
          value: "publier",
        },
        {
          name: "Rechercher",
          value: "rechercher",
        },
      ],
      sujet_message: "",
      message: "",
      messageSelect: null,
      MediaSelect: null,
      btnSelect: null,
      messageContent: [
        {
          sujet: "",
          message: "",
        },
      ],
      url_link: "",
      options: ["Sexe", "Taille", "Vehicule", "je vois"],
      users: [],
      resultFilter: [],
      usersdataId: [],
      selected: [],
      totalRows: 1,
      currentPage: 1,

      pageOptions: [25, 50, 100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "cocher", label: "Cocher" },
        { key: "nom", sortable: true, label: "Utilisateur" },
        { key: "manuel", sortable: true, label: "Manuel" },
        { key: "dateInscription", sortable: true, label: "Date Inscription" },
        { key: "type", sortable: true, label: "Type" },
        { key: "experience", label: "Experience" },
        { key: "badge", sortable: true, label: "Badge" },
        { key: "action", sortable: false, label: "Action" },
      ],
      reminderModal: false,
      showSucess: false,
      showEchec: false,
      showDelete: false,
      showEdit: false,
      showMessage: false,
      showMessageMail: false,
      editor: ClassicEditor,
      editorData: "",
      startValue: 1,
      endValue: 100,
      totalValue: "0",
      userChargeLoader: false,
      newDataUsers: [],
      recherche: "",
      parBon: 100,
      showSendMessage: false,
      showMessageWhatsapp: false
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return 100;
    },
  },
  async mounted() {
    this.userTotal();
    this.init();

  },


  methods: {


    async init() {

      const data = await apiRequest("GET", "admin/users", undefined, false);
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          }
          return {
            id: user.id,
            nom: nom + " " + prenom,
            dateInscription: new Date(user.dateCreation).toLocaleString("fr-FR"),
            type: type,
            experience: user.level,
            badge: user.userValidated,
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.filterOn = formattedTable;
        this.users = this.filterOn;
        this.userChargeLoader = false;
        this.usersdataId = [];
        for (let i = 0; i < this.users.length; i++) {
          this.usersdataId.push(this.users[i].id);
        }
      }
      // Set the initial number of items
      this.totalRows = this.users.length;


    },


    async getUser() {
      const data = await apiRequest("GET", "admin/users", undefined, false);
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "C. MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "C. VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          }
          return {
            id: user.id,
            nom: nom + " " + prenom,
            dateInscription: new Date(user.dateCreation).toLocaleString(
              "fr-FR"
            ),
            type: type,
            experience: user.level,
            badge: user.userValidated,
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.filterOn = formattedTable;
        this.users = this.filterOn;
        this.usersdataId = [];
        for (let i = 0; i < this.users.length; i++) {
          this.usersdataId.push(this.users[i].id);
        }
      }
      // Set the initial number of items
      this.totalRows = this.users.length;
    },


    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
        this.totalRows = datatotal.data.total;
      }
    },


    EditModal() {
      this.submitted = false;
      this.showEdit = false;
    },

    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = 100;
      this.currentPage = 1;
    },

    onChangeExpe(actionName) {
      this.userChargeLoader = true

      if (this.newDataUsers.length == 0) {
        this.init()
        setTimeout(() => {
          this.resultFilter = this.users.filter(function (utilisateur) {
            return utilisateur.experience == actionName.name;
          });
          this.users = this.resultFilter

          this.usersdataId = []
          for (let i = 0; i < this.resultFilter.length; i++) {
            this.usersdataId.push(this.resultFilter[i].id);
           
          }
          this.userChargeLoader = false
        }, 10000);

      }
      else {
        this.actif(this.startValue, this.endValue);
        setTimeout(() => {
          this.resultFilter = this.newDataUsers.filter(function (utilisateur) {
            return utilisateur.experience == actionName.name;
          });
          this.newDataUsers = this.resultFilter

          this.usersdataId = []
          for (let i = 0; i < this.resultFilter.length; i++) {
            this.usersdataId.push(this.resultFilter[i].id);
          }

          this.userChargeLoader = false
        }, 10000)
      }

    },


    onChangeBadge(actionName) {
      this.userChargeLoader = true
      this.usersdataId = []
      if (this.newDataUsers.length == 0) {

        this.init()
        setTimeout(() => {
          this.resultFilter = this.users.filter(function (utilisateur) {
            return utilisateur.badge == actionName.name;
          });
          this.users = this.resultFilter

          this.usersdataId = [];
          for (let i = 0; i < this.resultFilter.length; i++) {
            this.usersdataId.push(this.resultFilter[i].id);
          }

          this.userChargeLoader = false
        }, 10000);

      }

      else {
        this.actif(this.startValue, this.endValue);
        setTimeout(() => {
          this.resultFilter = this.newDataUsers.filter(function (utilisateur) {
            return utilisateur.badge == actionName.name;
          });
          this.newDataUsers = this.resultFilter


          this.usersdataId = [];
          for (let i = 0; i < this.resultFilter.length; i++) {
            this.usersdataId.push(this.resultFilter[i].id);
          }

          this.userChargeLoader = false
        }, 10000)

      }






    },
    AnnulerFilter() {
      this.users = this.filterOn;
    },

    onSendMessage() {
      this.showSendMessage = true;
      this.showMessageWhatsapp = false;
      this.showMessageMail = false;
      this.showMessage = false;

     
    },

    validSendMessage() {
      if (this.messageSelect.value == 0) {
        this.sendMessage();
       
      }
      if (this.messageSelect.value == 3) {
       
        this.sendMessageWhatsapp();
      }

      else {
        this.sendMessageMail();
       

      }
    },



    selectAll() {

     
      if (this.valueType !== null) {

        this.filteredData =
          this.newDataUsers.length != 0
            ? this.newDataUsers
            : this.users;
       

        this.filteredData.forEach(item => {

          if (this.selected.includes(item.id) == false) {
            this.selected.push(item.id)
            // item.selected =true
          }

        })
       
      } else {
        return this.reminderModal = true;
      }


    },

    selectAllAnyway() {



      this.filteredData =
        this.newDataUsers.length != 0
          ? this.newDataUsers
          : this.users;
     

      this.filteredData.forEach(item => {

        if (this.selected.includes(item.id) == false) {
          this.selected.push(item.id)
          // item.selected =true
        }

      })
      this.valueType = "all"
      this.reminderModal = false;
     
    },



    flushAllSelected() {
      this.selected = []
      this.valueType = null
    },

    async sendMessage() {
      const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.selected,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.message,
          media_type: this.MediaSelect.value, //media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,
          action: this.btnSelect.value != "" ? this.btnSelect.value : null,
        },
        false
      );
      if (message && message.data) {
        this.showMessage = false;
        this.showMessageMail = false;

        this.showSucess = true;
        this.showSendMessage = false;

        this.$toast.success("Message envoyé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.selected = []
      } else {
        this.showEchec = true;
        this.showSendMessage = false;
        this.$toast.error("Opération non effectuée.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.selected = []
      }
    },


    async sendMessageMail() {
      const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.selected,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.message,
          media_type: this.MediaSelect.value, //media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,

        },
        false
      );
      if (message && message.data) {
        this.showMessage = false;
        this.showMessageMail = false;

        this.showSucess = true;
        this.showSendMessage = false;

        this.$toast.success("Message envoyé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

        this.selected = []
      } else {
        this.showEchec = true;
        this.showSendMessage = false;
        this.$toast.error("Opération non effectuée.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

        this.selected = []
      }
    },

    // Whatsapp notification
    async sendMessageWhatsapp() {
      const message = await apiRequest(
        "POST",
        "admin/message-users",
        {
          idUsers: this.selected,
          typeMessage: this.messageSelect.value,
          subject: this.sujet_message,
          text: this.message,
          media_type: this.MediaSelect.value, //media_type: this.tyMedia.value ? this.tyMedia.value : "",
          url: this.url_link,

        },
        false
      );
      if (message && message.data) {
        this.showSendMessage = false;
        this.showMessageMail = false;


        this.showMessageWhatsapp = false;

        this.$toast.success("Message envoyé avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.selected = []
      } else {
        this.showEchec = true;
        this.showSendMessage = false;
        this.$toast.error("Opération non effectuée.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.selected = []
      }
    },



    loadMessage(typeMessage) {
      this.messageSelect = typeMessage;
      if (typeMessage.value == 0) {
        this.showMessage = true;
        this.messageSelect.value = 0
      }
      if (typeMessage.value == 1) {
        this.showMessageMail = true;
        this.messageSelect.value = 1
      }

      if (typeMessage.value == 3) {
        this.showMessageWhatsapp = true;
        this.messageSelect.value = 3
      }





    },

    async userSearch() {
     
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche?page=" + this.startValue + "&limit=" + this.endValue,

        { mots: this.recherche },
        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "C. MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "C. VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          }
          return {
            id: user.id,
            nom: nom + " " + prenom,
            dateInscription: new Date(user.dateCreation).toLocaleString(
              "fr-FR"
            ),
            type: type,
            experience: user.level,
            badge: user.userValidated,
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.newDataUsers = formattedTable;
        this.userChargeLoader = false;
        this.usersdataId = [];
        for (let i = 0; i < this.newDataUsers.length; i++) {
          this.usersdataId.push(this.newDataUsers[i].id);
        }

        this.totalRows = formattedTable.length
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= this.parBon;
      this.endValue -= this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += this.parBon;
      this.endValue += this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - this.parBon;
      this.endValue = this.totalValue;
     
      this.actif(this.startValue, this.endValue);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = this.parBon;
     
      this.actif(this.startValue, this.endValue);
    },

    async actif(page, limit) {
      //Chargement des données
      this.userChargeLoader = true;
      const data = await apiRequest(
        "GET",
        "admin/users?page=" + page + "&limit=" + limit,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          var nom = user.lastname == "" ? "Nom" : user.lastname;
          var prenom = user.firstname == "" ? "Prénom" : user.firstname;
          var type = user.userType == "" ? "" : user.userType;
          if (user.userType == "PARTICULIER" || user.userType == "CONDUCTEUR") {
            if (!user.vehicule) {
              type = "PASSAGER";
            } else if (user.userType == "CONDUCTEUR") {
              type = "MOTO";
            } else if (user.userType == "PARTICULIER") {
              type = "VOITURE";
            }
          }
          if (user.userType == "TAXIMAN") {
            type = "TAXI";
          }

          if (user.userType == "SOCIETE") {
            type = "BUS";
          }
          return {
            id: user.id,
            nom: nom + " " + prenom,
            dateInscription: new Date(user.dateCreation).toLocaleString(
              "fr-FR"
            ),
            type: type,
            experience: user.level,
            badge: user.userValidated,
            manuel: user.uid ? "Non" : "Oui",
          };
        });
        this.newDataUsers = formattedTable;
        this.users = []

        this.userChargeLoader = false;
        this.usersdataId = [];
        for (let i = 0; i < this.newDataUsers.length; i++) {
          this.usersdataId.push(this.newDataUsers[i].id);
        }


        this.totalRows = formattedTable.length
      }
    },

    messageShow(row, typeMessage) {
      this.messageSelect = typeMessage;

      if (typeMessage.value == 0) this.showMessage = true;
      if (typeMessage.value == 1) this.showMessageMail = true;
      if (typeMessage.value == 3) this.showMessageWhatsapp = true;
      this.messageContent.userId = row.id;
      this.usersdataId = []
      this.usersdataId.push(row.id);

     
    },
  },


  watch: {
    valueType(newValue) {
      this.userChargeLoader = true
      if (newValue) {
        this.resultFilter = [];
        this.usersdataId = [];
        this.selected = [];

        //console.log();

       
       
        // if users list is  empty
        if (this.newDataUsers.length == 0) {
          this.init()

          setTimeout(() => {
            this.resultFilter = this.users.filter(function (utilisateur) {
              return utilisateur.type == newValue.value;
            });

            this.users = this.resultFilter

            this.usersdataId = [];

            for (let i = 0; i < this.resultFilter.length; i++) {
              this.usersdataId.push(this.resultFilter[i].id);
             
              this.userChargeLoader = false
            }
          }, 10000);

        }


        // if users List not is empty
        else {
          this.usersdataId = []
          this.selected = []
          this.actif(this.startValue, this.endValue);

          setTimeout(() => {
            this.resultFilter = this.newDataUsers.filter(function (utilisateur) {
              return utilisateur.type == newValue.value;
            });
            this.newDataUsers = this.resultFilter
            this.usersdataId = [];
            for (let i = 0; i < this.resultFilter.length; i++) {
              this.usersdataId.push(this.resultFilter[i].id);
             
            }
            this.userChargeLoader = false
          }, 12000);


        }



      }


    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card containerDotContainer">
          <div class="containerDot" v-if="users.length == 0 && newDataUsers == 0">
            <span class="loading">Chargement en cours... </span>
            <!--  <div class="dots">
              <div class="dot dot-1"></div>
              <div class="dot dot-2"></div>
              <div class="dot dot-2"></div>
            </div> -->
          </div>
          <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
            <span class="loading">Chargement en cours...</span>
            <div aria-hidden="true"></div>
          </div>
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Utilisateurs</a>
                </template>
                <div class="row d-flex justify-content-between align-items-center p-2">
                  <div>Total de messages : {{ totalRows }}</div>
                  <transition mode="out-in">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <li>
                          <span class="interval"> {{ startValue }}</span> -
                          <span class="interval">{{ endValue }}</span> sur
                          {{ totalValue }}
                        </li>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <li>
                          <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                            data-placement="bottom" title="Prem">
                            <ion-icon name="play-skip-back-outline"></ion-icon>
                          </button>
                          <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == parBon"
                            data-toggle="tooltip" data-placement="bottom" title="Préc">
                            <ion-icon name="chevron-back-outline"></ion-icon>
                          </button>
                          <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                            title="Suiv" :disabled="endValue >= totalValue">
                            <ion-icon name="chevron-forward-outline"></ion-icon>
                          </button>
                          <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                            data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                            <ion-icon name="play-skip-forward-outline"></ion-icon>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </transition>
                </div>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3"></div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="recherche"
                        @keyup.enter="userSearch" />
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="row p-2 d-flex justify-content-between align-items-center mt-2">


                  <div class="p-3">

                    <button class="btn btn-success bg-green" @click="selectAll">
                      Tout sélectionner
                    </button>


                  </div>

                  <div class="p-3">

                    <button class="btn btn-warning" @click="flushAllSelected">
                      Tout désélectionner
                    </button>


                  </div>
                  <div class="col-md-4">
                    <label class="d-inline-flex align-items-center">
                      Type&nbsp;
                      <multiselect v-model="valueType" :options="userTypeList" label="value" track-by="value"
                        style="width: 150px !important"></multiselect>
                    </label>
                  </div>
                  <div class="col-md-4">
                    <label class="d-inline-flex align-items-center">
                      Expérience&nbsp;
                      <multiselect v-model="valueExp" :options="experience" label="name" track-by="name"
                        placeholder="Expérience" @select="onChangeExpe"></multiselect>
                    </label>
                  </div>
                  <div class="col-md-4">
                    <label class="d-inline-flex align-items-center">
                      Badge&nbsp;
                      <multiselect v-model="valueBadge" :options="badge" label="name" track-by="name" placeholder="Badge"
                        @select="onChangeBadge"></multiselect>
                    </label>
                    <button class="btn btn-info ml-4" @click="AnnulerFilter">
                      Annuler
                    </button>
                  </div>

                  <div class="col-md-3 mb-2">
                    <button class="btn btnsuccess mr-2" @click="loadMessage({ name: 'Notification', value: 0 })">
                      Notification
                    </button>
                    <button class="btn btnsuccess" @click="loadMessage({ name: 'Mail', value: 1 })">
                      Mail
                    </button>
                    <button class="btn btnsuccess ml-2" @click="loadMessage({ name: 'Whatsapp', value: 3 })">
                      Whatsapp
                    </button>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="newDataUsers.length != 0 ? newDataUsers : users"
                    :fields="fields" responsive="sm" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                    :filter-included-fields="filterOn" @filtered="onFiltered">

                    <template v-slot:cell(cocher)="row">
                      <div class="specialChecker" v-if="newDataUsers.length > 0 || users.length > 0">
                        <input type="checkbox" :value="row.item.id" v-model="selected" />
                      </div>

                      <!--<a
                    href="javascript:void(0);"
                    class="text-danger"
                    v-b-tooltip.hover
                    @click="cancelAction(row.item)"
                    title="Désactiver"
                  >
                    <i class="mdi mdi-close font-size-18"></i>
                  </a>-->
                    </template>

                    <template v-slot:cell(action)="row">

                      <a href="javascript:void(0);" class="text-warning mr-1" @click="
                        messageShow(row.item, {
                          name: 'Notification',
                          value: 0,
                        })
                        " v-b-tooltip.hover title="Notification">
                        <i class="fas fa-bell"></i>
                      </a>



                      <a href="javascript:void(0);" class="text-warning mr-1" @click="
                        messageShow(row.item, {
                          name: 'Mail',
                          value: 1,
                        })
                        " v-b-tooltip.hover title="Mail">
                        <i class="fas fa-bell"></i>
                      </a>
                    </template>
                    <!--   <template v-slot:cell(id)="row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="`customercheck${row.value}`"
                          @click="getId"
                        />
                        <label
                          class="custom-control-label"
                          :for="`customercheck${row.value}`"
                          >&nbsp;</label
                        >
                      </div>
                    </template> -->

                    <template v-slot:cell(badge)="row">
                      <div class="py-2 d-flex justify-content-center" :class="{
                        'fa fa-star': `${row.value}` === 'false',
                        'fa fa-star badge-soft-warning':
                          `${row.value}` === 'true',
                      }"></div>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <!-- <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
             
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div> -->
                    <transition mode="out-in">
                      <div class="dataTables_paginate paging_simple_numbers float-right">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <li>
                            <span class="interval"> {{ startValue }}</span> -
                            <span class="interval">{{ endValue }}</span> sur
                            {{ totalValue }}
                          </li>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <li>
                            <button class="myBouton" @click="firstVal()" :disabled="startValue == 1" data-toggle="tooltip"
                              data-placement="bottom" title="Prem">
                              <ion-icon name="play-skip-back-outline"></ion-icon>
                            </button>
                            <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == parBon"
                              data-toggle="tooltip" data-placement="bottom" title="Préc">
                              <ion-icon name="chevron-back-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="increment()" data-toggle="tooltip" data-placement="bottom"
                              title="Suiv" :disabled="endValue >= totalValue">
                              <ion-icon name="chevron-forward-outline"></ion-icon>
                            </button>
                            <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                              data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                              <ion-icon name="play-skip-forward-outline"></ion-icon>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </transition>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="reminderModal" title="Rappel" centered>
      <div class="body">

        <p>
          Vous n'avez pas selectionné de type de destinataire ! <br>
          Appuyez sur continuer pour selectionner tout les utilisateurs sur la page <br>
          Ou Fermer pour Annuler

        </p>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="reminderModal = false">Fermer</b-button>
        <b-button variant="primary" @click="selectAllAnyway()">
          Continuer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <!-- -->
    <b-modal v-model="showMessage" title="Nouveau Message" centered>
      <form>
        <label class="control-label">Choisir le type de message:</label>
        <multiselect v-model="messageSelect" :options="tyMessage" label="name" track-by="value"></multiselect>

        <div class="form-group">
          <input type="text" v-model="sujet_message" class="form-control" placeholder="Sujet" />
        </div>

        <div class="form-group">
          <ckeditor v-model="message" :editor="editor" aria-placeholder="Entrez votre message" id="myModal"></ckeditor>
          <label class="control-label">Media:</label>
          <multiselect v-model="MediaSelect" :options="tyMedia" label="name" track-by="value"></multiselect>
          <input type="text" v-model="url_link" class="form-control" placeholder="Lien du média" />
          <label class="control-label">Bouton:</label>
          <multiselect v-model="btnSelect" :options="tyButton" label="name" track-by="value"></multiselect>
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModal = false">Fermer</b-button>
        <b-button variant="primary" @click="onSendMessage()">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="showMessageMail" title="Nouveau Message" centered>
      <form>
        <label class="control-label">Choisir le type de message:</label>
        <multiselect v-model="messageSelect" :options="tyMessage" label="name" track-by="value"></multiselect>

        <div class="form-group">
          <input type="text" v-model="sujet_message" class="form-control" placeholder="Sujet" />
        </div>

        <div class="form-group">
          <ckeditor v-model="message" :editor="editor" aria-placeholder="Votre Message"></ckeditor>
          <multiselect v-model="MediaSelect" :options="tyMedia" label="name" track-by="value"></multiselect>
          <input type="text" v-model="url_link" class="form-control" placeholder="Lien du média" />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModal = false">Fermer</b-button>
        <b-button variant="primary" @click="onSendMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal v-model="showMessageWhatsapp" title="Nouveau Message " centered>
      <form>


        <div class="form-group mt-2">
          <input type="text" v-model="sujet_message" class="form-control" placeholder="Sujet" />
        </div>

        <div class="form-group">
          <textarea class="form-control" v-model="message" name="" id="" rows="10"></textarea>

          <multiselect v-model="MediaSelect" :options="tyMedia" label="name" track-by="value" class="mt-2"></multiselect>
          <input type="text" v-model="url_link" class="form-control mt-2" placeholder="Lien du média" />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showMessageWhatsapp = false">Fermer</b-button>
        <b-button variant="primary" @click="onSendMessage">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showSendMessage" title="Confirmez-vous l'envoi du message"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showSendMessage = false">Annuler</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="validSendMessage">Valider</b-button>
      </div>
    </b-modal>

    <!-- -->
  </Layout>
</template>
<style>
thead {
  background: #3AAA35;
}


.btnsuccess {
  background: #3AAA35;
  color: #fff;
}


thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3AAA35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}


.paging_simple_numbers ul li button {
  color: #fff !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -50px;
}

.containerDotContainer {
  position: relative;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 2%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.ck.ck-balloon-panel {
  z-index: 1050 !important;
}

input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  height: 40px;
  width: 40px;
  background-color: #d5d5d5;

  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
    10px 10px 15px rgba(70, 70, 70, 0.12);
}


.specialChecker input[type="checkbox"]:after {
  font-weight: 600;
  font-family: FontAwesome;
  content: "\f00c";

  font-size: 20px;
  color: white;
  display: none;
}

.specialChecker input[type="checkbox"]:hover {
  background-color: #a5a5a5;
}

.specialChecker input[type="checkbox"]:checked {
  background-color: #5bcd3e;
}

.specialChecker input[type="checkbox"]:checked:after {
  display: block;
}
</style>
